import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "./loginSlice";
import categoriesReducer from "./categoriesSlice";
import subcategoriesReducer from "./subcategoriesSlice";
import searchResutlsReducer from "./searchResultsSlice";
import recentUpdatesReducer from "./recentUpdatesSlice";
import litigaitonsReducer from "./litigationsSlice";
export const store = configureStore({
  reducer: {
    loginStatus: loginReducer,
    categories: categoriesReducer,
    subcategories: subcategoriesReducer,
    searchResults: searchResutlsReducer,
    recentUpdates: recentUpdatesReducer,
    litigations: litigaitonsReducer,
  },
});
