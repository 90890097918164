import { createSlice } from "@reduxjs/toolkit";

const recentUpdates = JSON.parse(localStorage.getItem("recentUpdates"));
const initialState = {
  value: recentUpdates || [],
};

export const recentUpdatesSlice = createSlice({
  name: "recentUpdates",
  initialState,
  reducers: {
    setRecentUpdates: (state, action) => {
      state.value = action.payload;
      localStorage.setItem("recentUpdates", JSON.stringify(action.payload));
    },
  },
});

export const { setRecentUpdates } = recentUpdatesSlice.actions;
export default recentUpdatesSlice.reducer;
