import axios from "axios";
// Function to execute GraphQL queries
async function fetchGraphQL(query, variables = {}, operationName = null) {
  const result = await axios.post(
    "https://api.sainyamitra.in/v1/graphql",

    {
      query,
      variables,
      operationName,
    },
    {
      headers: {
        "Content-Type": "application/json",
        "x-hasura-admin-secret": "legalpwd123",
      },
    }
  );

  return result.data;
}

// Function to fetch the example query
export async function fetchQuery(query) {
  const { errors, data } = await fetchGraphQL(query);

  if (errors) {
    console.error(errors);
    throw new Error(errors);
  } else {
    return data;
  }
}

// Search query
const searchQuery = `query MyQuery($searchTerm: String!) {
  legalapp_article(where: {_or: [{question: {question: {_ilike: $searchTerm}}}, {answer: {answer: {_ilike: $searchTerm}}}]}) {
    question {
      question
      id
    }
    answer {
      answer
    }
    sub_category_id
  }
}`;
export async function fetchQuerySearch(searchText) {
  const { errors, data } = await fetchGraphQL(searchQuery, {
    searchTerm: `%${searchText}%`,
  });

  if (errors) {
    console.error(errors);
    throw new Error(errors);
  } else {
    return data;
  }
}
// article from search
const searchArticleQuery = `query MyQuery($_eq: uuid = "", $_eq1: uuid = "") {
  legalapp_article(where: {_and: {sub_category_id: {_eq: $_eq}, question_id: {_eq: $_eq1}}}) {
    question {
      id
      question
    }
    answer {
      id
      answer
    }
    judgement {
      id
      title
      topic
      full_content
      head_notes
      section_name
      file_url
    }
    provision {
      id
      title
      full_content
    }
  }
}`;
export async function fetchQuerySearchArticle(sub_cat_id, question_id) {
  const { errors, data } = await fetchGraphQL(searchArticleQuery, {
    _eq: sub_cat_id,
    _eq1: question_id,
  });

  if (errors) {
    console.error(errors);
    throw new Error(errors);
  } else {
    return data;
  }
}
// recent updates
const recentUpdatesQuery = `
query MyQuery {
  legalapp_article(order_by: {created_at: desc}, limit: 10){
    question {
      id
      question
    }
    answer {
      id
      answer
    }
    judgement {
      id
      title
      topic
      full_content
      head_notes
      section_name
    }
    provision {
      id
      title
      full_content
    }
    sub_category_id
  }
}`;
export async function fetchQueryRecentUpdates() {
  const { errors, data } = await fetchGraphQL(recentUpdatesQuery);

  if (errors) {
    console.error(errors);
    throw new Error(errors);
  } else {
    return data;
  }
}
// litigations
const litigationsQuery = `query MyQuery($_eq: uuid = "") {
  legalapp_litigation(where: {user_id: {_eq: $_eq}}) {
    id
    case_id_from_user
    forum
    litigation_case_histories {
      id
      litigation_id
      date
      status
    }
    json_info
    litigation_orders {
      Date
      description
      file_url
      litigation_id
      id
    }
    litigations_pleadings {
      id
      litigation_id
      file_url
      pleading
    }
    status
  }
}

`;
export async function fetchQueryLitigations(user_id) {
  const { errors, data } = await fetchGraphQL(litigationsQuery, {
    _eq: user_id,
  });

  if (errors) {
    console.error(errors);
    throw new Error(errors);
  } else {
    return data;
  }
}

// help data
const helpQuery = `query MyQuery {
  legalapp_app_settings {
    version_number
    settings
  }
}`;
export async function fetchQueryHelpDetails() {
  const { errors, data } = await fetchGraphQL(helpQuery);

  if (errors) {
    console.error(errors);
    throw new Error(errors);
  } else {
    return data;
  }
}
// videos
const videosQuery = `query MyQuery {
  legalapp_videos {
    id
    category
    title
    video_url
  }
}`;
export async function fetchQueryVideos() {
  const { errors, data } = await fetchGraphQL(videosQuery);

  if (errors) {
    console.error(errors);
    throw new Error(errors);
  } else {
    return data;
  }
}

const formsQuery = `query MyQuery {
  legalapp_forms {
    id
    title
    template_url
  }
}`;
export async function fetchQueryForms() {
  const { errors, data } = await fetchGraphQL(formsQuery);

  if (errors) {
    console.error(errors);
    throw new Error(errors);
  } else {
    return data;
  }
}

const userQuery = `query MyQuery($id: uuid = "") {
  legalapp_app_user_by_pk(id: $id) {
    app_user_metadata {
      user_id
      name
      email_id
    }
    phonenumber
  }
}`;
export async function fetchQueryUser(user_id) {
  const { errors, data } = await fetchGraphQL(userQuery, {
    id: user_id,
  });

  if (errors) {
    console.error(errors);
    throw new Error(errors);
  } else {
    return data;
  }
}

// Start fetching the example query
// fetchCategoriesQuery();
