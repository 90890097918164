import { Routes, Route } from "react-router-dom";
import React, { lazy, useEffect } from "react";
// import useScreenSize from "./customHooks/useScreenSize";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { fetchQuery } from "./api/helpers/catHelper";
import { setCategories } from "./redux/categoriesSlice";
import { setSubCategories } from "./redux/subcategoriesSlice";
import { startTransition } from "react";

const Home = lazy(() => import("./pages/Home/Home"));
const Help = lazy(() => import("./pages/Help/Help"));
const Litigation = lazy(() => import("./pages/Litigation/Litigation"));
const Settings = lazy(() => import("./pages/Settings/Settings"));
const MobileNav = lazy(() => import("./components/MobileNav/MobileNav"));
const Updates = lazy(() => import("./pages/Updates/Updates"));
const Watch = lazy(() => import("./pages/Watch/Watch"));
const Login = lazy(() => import("./pages/Login/Login"));
const SignUp = lazy(() => import("./pages/SignUp/SignUp"));
const OTP = lazy(() => import("./pages/OTP/OTP"));
const Article = lazy(() => import("./pages/Article/Article"));
const Case = lazy(() => import("./pages/Case/Case"));
const Forms = lazy(() => import("./pages/Forms/Forms"));
const SubCatArticles = lazy(() =>
  import("./pages/SubCatArticles/SubCatArticles")
);

const notFound = () => {
  return (
    <div className="fs-1 min-vh-100 d-flex justify-content-center align-items-center text-center">
      404
      <br />
      Page Not Found
    </div>
  );
};

function App() {
  // const screenSize = useScreenSize();
  const dispatch = useDispatch();

  useEffect(() => {
    startTransition(() => {
      const categoriesQuery = `query MyQuery {
        legalapp_content_categories {
          category
          id
          content_sub_categories {
            sub_category,
            id,
          }
        }
      }`;
      const subcategoriesQuery = `query MyQuery {
        legalapp_content_sub_categories {
          articles {
            id
            answer {
              answer
              id
            }
            judgement {
              title
              topic
              head_notes
              full_content
              file_url
            }
            question {
              id
              question
            }
            provision {
              full_content
              title
            }
            sub_category_id
          }
          sub_category
          id
          category_id
        }
      }`;
      fetchQuery(categoriesQuery)
        .then((data) => {
          dispatch(setCategories(data));
        })
        .catch((err) => {
          console.log(err);
          toast.error("Something went wrong");
        });

      fetchQuery(subcategoriesQuery)
        .then((data) => {
          dispatch(setSubCategories(data));
        })
        .catch((err) => {
          console.log(err);
          // toast.error("Something went wrong");
        });
      console.log("data fetched");
    });

    // eslint-disable-next-line
  }, []);

  // if (screenSize > 600) {
  //   return (
  //     <div className="min-vh-100 d-flex justify-content-center align-items-center fs-4 fw-bold">
  //       Please open this site on Mobile
  //     </div>
  //   );
  // }

  return (
    <div className="min-375">
      <ToastContainer />
      {/* <Suspense
        fallback={
          <h1 className="min-vh-100 d-flex align-items-center justify-content-center">
            Loading...
          </h1>
        }
      > */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/otp" element={<OTP />} />
        <Route path="/:subcatId" element={<SubCatArticles />} />
        <Route path="/:subcatId/:articleId" element={<Article />} />
        <Route path="/updates" element={<Updates />} />
        <Route path="/updates/:articleId" element={<Article />} />
        <Route path="/search/:sub_cat_id/:question_id" element={<Article />} />
        <Route path="discover" element={<Watch />} />
        <Route path="/help" element={<Help />} />
        <Route path="/litigation" element={<Litigation />} />
        <Route path="/litigation/:caseId" element={<Case />} />
        <Route path="/forms" element={<Forms />} />
        <Route path="/settings" element={<Settings />} />

        <Route path="*" element={notFound()} />
      </Routes>
      <div
        style={{
          boxShadow: "0px -2px 10px 0px #00000033",
        }}
        className={`w-100 position-fixed fixed-bottom p-0 d-block z-3 bg-white `}
      >
        <MobileNav />
      </div>
    </div>
  );
}

export default App;
