import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  value: [],
};

const searchResultSlice = createSlice({
  name: "searchResults",
  initialState,
  reducers: {
    setSearchResults: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setSearchResults } = searchResultSlice.actions;
export default searchResultSlice.reducer;
