import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: [],
};

export const subcategoriesSlice = createSlice({
  name: "subcategories",
  initialState,
  reducers: {
    setSubCategories: (state, action) => {
      state.value = action.payload;
    },
  },
});
export const { setSubCategories } = subcategoriesSlice.actions;
export default subcategoriesSlice.reducer;
