import { createSlice } from "@reduxjs/toolkit";

const token = localStorage.getItem("lapp_token");
const user_id = localStorage.getItem("lapp_user_id");

const initialState = {
  value: token ? true : false,
  user_id: user_id,
};

export const loginSlice = createSlice({
  name: "loginStatus",
  initialState,
  reducers: {
    changeLoginStatus: (state, action) => {
      state.value = action.payload;
    },
    set_user_id: (state, action) => {
      state.user_id = action.payload;
    },
  },
});

export const { changeLoginStatus, set_user_id } = loginSlice.actions;
export default loginSlice.reducer;
